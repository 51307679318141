@import "../../../variables.scss";

.tabContentWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  width: 100%;
  padding: 0 20px;
}

.contentRow {
  display: flex;
  flex-direction: row;
  margin-top: 4px;
  align-items: center;
  border-bottom: 1px solid #e8e8e8;
  padding: 3px 0px;
  min-height: 40px;
}

.contentBackground {
  background-color: $contentBackground;
  border-radius: $contentBorderRadius;
  padding: 10px 20px;
}

.listWrapper {
  box-shadow: 5px 5px 5px 5px gray;
  border-width: 1px;
  border-color: gray;
  border-radius: 4px;
  width: 90%;
  overflow-y: scroll;
  padding: 4px 10px;
  overflow-x: hidden;
  min-height: 350px;
  // margin-top: 5%;
}

.clearButton {
  display: flex;
  justify-content: flex-end;
  width: 90%;
  padding-top: 10px;
  padding-bottom: 20px;
  // margin-top: 5%;
}


.inviteRow {
  margin: 10px;
  display: flex;
  flex-direction:row;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
}


.inviteListHeader {
  font-weight: bold;
  color: $primaryThemeDark;
  text-align: center;
}

.inviteListEntry {
  text-align: center;
}

.clickableText:hover {
  text-decoration: underline;
}