@import "../../../../../variables";

.tableWrapper {
  width: 100%;
}

.tableRow {
  display: flex;
  flex-direction: row;
  width: 100%;
  border-bottom: 1px solid #e8e8e8;
  padding: 6px 0px;
}

.closeModalBtn {
  position: absolute;
  top: 5px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
  padding: 4px 10px;
}

.closeModalBtn:hover {
  background-color: #acacac;
}

.questionContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  height: 100%;
  overflow-y: scroll;
  padding-bottom: 20px;
  padding-top: 20px;
}

.inviteRow {
  margin: 10px;
  display: flex;
  flex-direction:row;
  align-items: center;
  justify-content: center;
  width: 95%;
  position: relative;
}


.inviteListHeader {
  font-weight: bold;
  color: $primaryThemeDark;
  text-align: center;
}

.inviteListEntry {
  text-align: center;
}

.row {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 10px;
  margin-left: 30px;
}

.rowLabel {
  color: $primaryColor;
  // margin-right: 30px;
  width: 20%;
}
