@import "../../variables.scss";
.rowIcon{
  flex-direction: column;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  cursor: pointer;
}

.td {
  padding: 4px;
}

.sectionHeader {
  color: $primaryColor;
  margin-bottom: 4px;
  width: 100%;
  text-align: center;
}

.value {
  color: $primaryColor;
  margin-left: 4px;
}

.chartWrapper {
  width: 90%;
  margin-top: 30px;
  box-shadow: 5px 5px 5px 5px grey;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.painChartTooltip {
  height: 100px;
  width: 200px;
  background-color: #FFFFFA;
  border: 1px solid gray;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.radioConatiner {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 14px;
  color: #666;
  font-weight: 500;
}

.radioConatinerNew {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 14px;
  color: #666;
  font-weight: 500;
}

/* radio button */
.radioConatiner input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.radioConatiner .checkmark {
  position: absolute;
  top: 3px;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: #ffffff;
  border-radius: 50%;
  border: 2px solid #A1A1A1
}


.radioConatiner:hover input ~ .checkmark {
  background-color: #ffffff;
}

.radioConatiner input:checked ~ .checkmark {
  background-color: #ffffff;
}

.radioConatiner .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.radioConatiner input:checked ~ .checkmark:after {
  display: block;
}

.radioConatiner .checkmark:after {
  top: 1.5px;
  left: 2.5px;
  width: 10px;
  height: 10px;
	border-radius: 50%;
	background: #20A892;
}

//coardio second vie radio button
.radioConatinerNew input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.radioConatinerNew .checkmark {
  position: absolute;
  top: 3px;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: #ffffff;
  border-radius: 50%;
  border: 2px solid #A1A1A1
}


.radioConatinerNew:hover input ~ .checkmark {
  background-color: #ffffff;
}

.radioConatinerNew input:checked ~ .checkmark {
  background-color: #ffffff;
}

.radioConatinerNew .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.radioConatinerNew input:checked ~ .checkmark:after {
  display: block;
}

.radioConatinerNew .checkmark:after {
  top: 1.5px;
  left: 1.5px;
  width: 10px;
  height: 10px;
	border-radius: 50%;
	background: #20A892;
}

// Custom Square Checkbox
.squareConatiner {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  line-height: 15px;
  cursor: pointer;
  font-size: 13px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.squareConatiner input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.squareConatiner .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 15px;
  width: 15px;
  border: 2px solid #A1A1A1;
}
// .squareConatiner:hover input ~ .checkmark {
//   background-color: #ccc;
// }
.squareConatiner input:checked ~ .checkmark {
  border: 2px solid #A1A1A1;
  color: #20A892;
}
.squareConatiner .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.squareConatiner input:checked ~ .checkmark:after {
  display: block;
}
.squareConatiner .checkmark:after {
  left: 3px;
  top: 0px;
  width: 5px;
  height: 10px;
  border: solid #20A892;
  border-width: 0 1px 1px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.squareConatiner input~.checkmark:before {
  transform: rotate(45deg);
  z-index: 1;
}
.squareConatiner input:checked ~ .checkmark:after, .squareConatiner input:checked ~ .checkmark:before {
  content: '';
  position: absolute;
  width: 2px;
  height: 16px;
  background: #20A892;
  left: 5px;
  top: -2px;
}
.squareConatiner input~.checkmark:after {
  transform: rotate(-45deg);
  z-index: 1;
}



.customFormControl .formControl{
  display: block;
  width: 100%;
  height: 30px;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 8px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.chartClass {
  border-radius: 0px;
  overflow: hidden;
  background: #ffffff;
  margin-bottom: 15px;
  // padding: 20px 20px;
  padding-left: 20px;
  padding-right: 30px;
  padding-top: 60px;
  padding-bottom: 30px;
  box-shadow: 5px 5px 5px 5px grey;
  margin-bottom: 25px;
}

.chartClassOld {
  border-radius: $contentBorderRadius;
  overflow: hidden;
  background: #ffffff;
  padding: 20px 20px;
  box-shadow: 3px 3px 3px 3px grey;
  // margin-top: 20px;
  // margin-left: 40px;
  width: 100%
}

.hoverChatWrapper {
  box-shadow: 5px 5px 5px 5px grey;
}

.chartClass1 {
  border-radius: 0px;
  overflow: hidden;
  background: #ffffff;
  margin-bottom: 15px;
  // padding: 20px 20px;
  padding-left: 0px;
  padding-right: 20px;
  padding-top: 60px;
  padding-bottom: 10px;
  box-shadow: 5px 5px 5px 5px grey;
  margin-bottom: 20px;
}

.chartClass2 {
  border-radius: 0px;
  overflow: hidden;
  background: #ffffff;
  margin-bottom: 15px;
  // padding: 20px 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 60px;
  padding-bottom: 30px;
  box-shadow: 5px 5px 5px 5px grey;
  margin-bottom: 20px;
}

.ecgChartClass{
  border-radius: 15px;
  overflow: hidden;
  background-color: #f8f8f8;
  margin-bottom: 15px;
  // padding: 20px 20px;
  // padding-left: 0px;
  // padding-right: 30px;
  // padding-top: 60px;
  // padding-bottom: 20px;
}

.ecgChartClass1{
  border-radius: 15px;
  overflow: hidden;
  background-color: #ecf7f7;
  margin-bottom: 15px;
  // padding: 20px 20px;
  // padding-left: 0px;
  // padding-right: 30px;
  // padding-top: 60px;
  // padding-bottom: 20px;
}

.yAxisLabelOld{
  position: absolute;
  left: 30px;
  top: 25px;
}

.yAxisLabelPOld{
  font-weight: bold;
  font-size: 14px;
}

.yAxisLabel{
  position: absolute;
  left: 280px;
  top: 25px;
}

.yAxisLabelP{
  font-weight: bold;
  font-size: 14px;
}

.yAxisLabelR2{
  position: absolute;
  left: 350px;
  top: 25px;
}

.yAxisLabelPR2{
  font-weight: bold;
  font-size: 14px;
}

.yAxisLabelImmune{
  position: absolute;
  left: 250px;
  top: 25px;
}

.yAxisLabelPImmune{
  font-weight: bold;
  font-size: 14px;
}

.xAxisLabel{
  flex-direction: row;
  align-self: flex-end;
  width: 30px;
  margin-left: 710px;
  margin-top: -25px;
}

.xAxisLabelText{
  align-self: flex-end;
  font-weight: bold;
  font-size: 14px;
}
.customTooltip{
  width: 150px;
  height: 38px;
  border-radius: 30px;
  background-color: #fff;
  justify-content: center;
  padding-top: 1px;
  border-width: 5px;
  border-color: #8a8a8a;
}
.tooleTipLabel{
  align-self: center;
  margin-top: 5px;
  margin-left: 20px;
  font-size: 14px;
}
.monthDiv{
  width: 100px;
  height: 35px;
  border-radius: 45px;
  background-color: #fff;
  justify-content: center;
  padding-top: 1px;
  border-width: 5px;
  border-color: #8a8a8a;
  position: absolute;
  right: 30px;
  top: 16px;
}
.monthLabel{
  align-self: center;
  margin-top: 4px;
  margin-left: 25px;
  font-size: 14px;
}

.squareBox1{
  height: 15px;
  width: 15px;
  border: 2px solid #A1A1A1;
  align-self: center;
}
.squareBox2{
  height: 15px;
  width: 15px;
  border: 2px solid #A1A1A1;
  align-self: center;
}
.squareBox3{
  height: 15px;
  width: 15px;
  border: 2px solid #A1A1A1;
  align-self: center;
}
.squareBox1Text{
  font-size: 13px;
  align-self: center;
  margin-left: 10px;
}
.squareBox2Text{
  font-size: 13px;
  align-self: center;
  margin-left: 10px;
}
.squareBox3Text{
  font-size: 13px;
  align-self: center;
  margin-left: 10px;
}
.rowDirectionBox{
  flex-direction: row;
  height: 20px;
  margin-top: 10px;
  // justify-content: space-between;
  display: flex;
}


// Custom select dropdown on graph
.chartSelect {
  position: absolute;
  top: 20px;
  right: 45px;
  display: block;
  width: 140px;
  font-size: 13px;
  color: #60666d;
}
.chartECGSelect {
  position: absolute;
  top: 20px;
  right: 46px;
  display: block;
  width: 140px;
  font-size: 13px;
  color: #60666d;
}
.chartSelectCurrent {
  position: relative;
  box-shadow: 0 15px 30px -10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  outline: none;
}
.chartSelectCurrent:focus + .chartSelectList {
  opacity: 1;
  -webkit-animation-name: none;
          animation-name: none;
}
.chartSelectCurrent:focus + .chartSelectLst .chartSelectOption {
  cursor: pointer;
}
.chartSelectCurrent:focus .chartSelectIcon {
  transform: translateY(-50%) rotate(180deg);
}
.chartSelectIcon {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  width: 12px;
  opacity: 1;
  transition: 0.2s ease;
}
.chartSelectValue {
  display: flex;
}
.chartSelectInput {
  display: none;
}
.chartSelectInput:checked + .chartSelectInputText {
  display: block;
}
.chartSelectInputText {
  display: none;
  width: 100%;
  margin: 0;
  padding: 4px;
  background-color: #fff;
  border-radius: 50px;
  border: 2px solid #A1A1A1;
  text-align: center;
}
.chartSelectList {
  position: absolute;
  width: 100%;
  padding: 0;
  list-style: none;
  opacity: 0;
  -webkit-animation-name: HideList;
          animation-name: HideList;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-timing-function: step-start;
          animation-timing-function: step-start;
  box-shadow: 0 15px 30px -10px rgba(0, 0, 0, 0.1);
  z-index: 999;
  background: #fff;
  top: 35px;
  border-radius: 10px;
}
.chartSelectOption {
  display: block;
  padding: 8px;
  background-color: #fff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.chartSelectOption:hover, .chartSelectOption:focus {
  color: #546c84;
  background-color: #fbfbfb;
}

@-webkit-keyframes HideList {
  from {
    transform: scaleY(1);
  }
  to {
    transform: scaleY(0);
  }
}

@keyframes HideList {
  from {
    transform: scaleY(1);
  }
  to {
    transform: scaleY(0);
  }
}

.esc2LabelView{
  position: absolute;
  left: 301px;
  top: 25px;
}

.esc2Label{
  font-weight: bold;
  font-size: 14px;
  align-self: center;
}

.reproductiveChartClass {
  border-radius: 0px;
  overflow: hidden;
  background: #ffffff;
  margin-bottom: 15px;
  // padding: 20px 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 60px;
  padding-bottom: 30px;
  box-shadow: 5px 5px 5px 5px grey;
}

//reproduction select dropdown
.chartSelectReproductive {
  position: absolute;
  top: 20px;
  right: 65px;
  display: block;
  width: 120px;
  font-size: 13px;
  color: #60666d;
}
.chartSelectReproductiveCurrent {
  position: relative;
  box-shadow: 0 15px 30px -10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  outline: none;
}
.chartSelectReproductive:focus + .chartSelectReproductiveList {
  opacity: 1;
  -webkit-animation-name: none;
          animation-name: none;
}
.chartSelectReproductiveCurrent:focus + .chartSelectReproductiveList .chartSelectReproductiveOption {
  cursor: pointer;
}
.chartSelectReproductiveCurrent:focus .chartSelectReproductiveIcon {
  transform: translateY(-50%) rotate(180deg);
}
.chartSelectReproductiveIcon {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  width: 12px;
  opacity: 1;
  transition: 0.2s ease;
}
.chartSelectReproductiveValue {
  display: flex;
}
.chartSelectReproductiveInput {
  display: none;
}
.chartSelectReproductiveInput:checked + .chartSelectReproductiveInputText {
  display: block;
}
.chartSelectReproductiveInputText {
  display: none;
  width: 100%;
  margin: 0;
  padding: 4px;
  background-color: #fff;
  // border-radius: 50px;
  border: 2px solid #A1A1A1;
  text-align: center;
}
.chartSelectReproductiveList {
  position: absolute;
  width: 100%;
  padding: 0;
  list-style: none;
  opacity: 0;
  -webkit-animation-name: HideList;
          animation-name: HideList;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-timing-function: step-start;
          animation-timing-function: step-start;
  box-shadow: 0 15px 30px -10px rgba(0, 0, 0, 0.1);
  z-index: 999;
  background: #fff;
  top: 35px;
  border-radius: 10px;
}
.chartSelectReproductiveOption {
  display: block;
  padding: 8px;
  background-color: #fff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.chartSelectReproductiveOption:hover, .chartSelectReproductiveOption:focus {
  color: #546c84;
  background-color: #fbfbfb;
}
//Immuno system
.autoImmunoLabel{
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #20A892;
  align-self: center;
}
.geneticLabel{
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #20A892;
  align-self: center;
}
.autoimmunoTableDiv{
  background: #F8F8F8;
  border: 1px solid #F8F8F8;
  box-sizing: border-box;
  border-radius: 18px;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  margin-top: 30px;
  align-self: flex-start;
}
.geneticTableDiv{
  background: #F8F8F8;
  border: 1px solid #F8F8F8;
  box-sizing: border-box;
  border-radius: 18px;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  margin-top: 30px;
  width: 600px;
  height: 720px;
}
.geneticTableDiv .table, th, td{
  // border: 0px;
}
.secondClass{
  border: none;
}
.firstClass{
  border: none;
}
.firstClass td, .firstClass th{
  border: none;
  border-bottom: 1px solid #DEDEDE;
}
.secondClass td, .secondClass th{
  border: none;
  border-bottom: 1px solid #DEDEDE;
}
.chartSelectReproductive span{
  background-color: transparent;
}
.selectStyle{
  height: 25px !important;
  border-width: 2px !important;
  border-radius: 0px;
  border-color: #CFCFCF !important;
  width: 100px;
}
.chartSelectReproductive .selectStyle{
  height: 25px !important;
  border-width: 2px;
  border-radius: 0px;
  border-color: #CFCFCF;
}

.selectStyleInnerGraph{
  height: 25px !important;
  border-width: 2px !important;
  border-radius: 0px;
  border-color: #CFCFCF !important;
}

.selectOuter .selectStyleInner{
  padding-left: 10px;
  border-width: 2px !important;
  border-radius: 0px;
  border-color: #CFCFCF !important;
}

.selectStyle span{
  background-color: transparent;
}

.row {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 10px;
  margin-left: 30px;
}

.rowLabel {
  color: $primaryColor;
  // margin-right: 30px;
  width: 20%;
}

.questionContainer {
  display: flex;
  flex-direction: column;
  width: 70%;
  align-items: center;
  border-radius: $contentBorderRadius;
  overflow: hidden;
  background: #ffffff;
  padding: 20px 20px;
  box-shadow: 3px 3px 3px 3px grey;
  margin-top: 20px;
  margin-left: 30px;
  margin-bottom: 30px;
}

.inviteRow {
  margin: 10px;
  display: flex;
  flex-direction:row;
  align-items: center;
  justify-content: center;
  width: 95%;
  position: relative;
}


.inviteListHeader {
  font-weight: bold;
  color: $primaryThemeDark;
  text-align: center;
}

.inviteListEntry {
  text-align: center;
}

