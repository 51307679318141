@import '../../../variables';

.propIcon {
  color: $primaryThemeDark;
  font-size: 20px;
}

.propIcon1 {
  color: $primaryColorFaded;
  font-size: 20px;
}

.propIconWrapper {
  width: 20%;
}

.parentDiv {
  display: flex;
  flex-wrap: wrap;
  align-self: center;
  justify-content: center;
  width: 80%;
  margin: auto;
}

.childDiv {
  flex: 1 0 21%;
  margin: 2vh 2vh;
  border-radius: 5px;
  border: 1px solid $primaryColor;
  max-width: 40%;
}

.valuePropsWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.valuePropsBoxAila1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 10px;
  width: 20%;
  // border-radius: 4px;
  // border: 1px solid gray;
  cursor: default;
}

.valuePropsBoxAila {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 10px;
  border-radius: 4px;
  border: 1px solid gray;
  cursor: pointer;
  width: inherit;
}

.valuePropsBoxAila:hover {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 10px;
  border-radius: 4px;
  border: 1px solid gray;
  background-color: $primaryThemeFadedDeep;
}

.heading {
  flex: 1;
  align-self: center;
  margin-top: -8vh;
}

@media only screen and (max-width: 600px) {
  .valuePropsWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 98%;
  }

  .commImageWrapper {
    width: 175px;
    height: 175px;
  }

  .modalContent {
    width: 95%;
    height: 30%;
  }

  .modalButtonDiv {
    height: 25%;
    width: 100%;
  }

  .heading {
    flex: 1;
    align-self: center;
    margin-top: 1vh;
  }
}
