@import "../../../variables.scss";

.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 90vh;
    padding-top: 100px;
}

.textInput {
    margin-top: 40px;
    width: 500px;
}

.textInput2 {
    margin: 20px;
    width: 50%;
}

.modalContentWrapper {
    padding-top: 20px;
    width: 100%;
    height: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
}

.rowLabel {
    color: $primaryColor;
    margin-right: 30px;
    width: 20%;
}

.textInput2 {
    border: 1px solid #d0d0d0;
    padding: 5px 20px;
    border-radius: 4px;
    width: 50%;
}
