@import '../../../../variables.scss';

.tabItem {
  border-right-width: 1px;
  border-right-color: gray;
  border-right-style: solid;
  flex-grow: 1;
  text-align: center;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.listWrapper {
  box-shadow: 5px 5px 5px 5px gray;
  border-width: 1px;
  border-color: gray;
  border-radius: 4px;
  width: 80%;
  // overflow-y: scroll;
  padding: 4px 10px;
  // overflow-x: hidden;
  min-height: 40vh;
  margin: 2% auto;
}

.textInput {
  border: 1px solid #d0d0d0;
  padding: 5px 20px;
  border-radius: 4px;
  width: 50%;
}

.closeModalBtn {
  position: absolute;
  top: 5px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
  padding: 4px 10px;
}

.closeModalBtn:hover {
  background-color: #acacac;
}

.tabItem {
  border-right-width: 1px;
  border-right-color: gray;
  border-right-style: solid;
  flex-grow: 1;
  text-align: center;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.inviteEntryColumnHeader {
  text-align: center;
  font-weight: bold;
  color: $primaryThemeDark;
}

.inviteEntryColumn {
  text-align: center;
}

.row {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}

.rowLabel {
  color: $primaryColor;
  margin-right: 30px;
  width: 20%;
}

.patientModalEntryForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  background-color: #e8e8e8;
  border-radius: 10px;
  padding: 20px 20px;
  margin-top: 20px;
  position: relative;
}

.inviteRow {
  margin: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
}

.divider {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 10px;
}

.rowIcon {
  flex-direction: column;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  cursor: pointer;
}

.inviteListHeader {
  font-weight: bold;
  color: $primaryThemeDark;
  text-align: center;
}

.inviteListEntry {
  text-align: center;
}

.surveyQuestionForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #e8e8e8;
  border-radius: 10px;
  padding: 20px 20px;
  margin-top: 20px;
  position: relative;
  min-height: 140px;
}

.studyEntryRow {
  display: flex;
  flex-direction: row;
  width: 90%;
  margin-top: 14px;
  align-items: center;
  margin: 2% auto;
}

.studyEntryKey {
  width: 200px;
  font-weight: bold;
  font-size: 16px;
}

.studyEntryInput {
  border: 1px solid #d0d0d0;
  padding: 5px 20px;
  border-radius: 4px;
  width: 60%;
}

.postImage {
  height: 80px;
  width: 80px;
  object-fit: cover;
  border-radius: 50%;
}

.bottomInputWrapper {
  position: sticky;
  bottom: 0px;
  height: 10%;
  display: flex;
  flex-direction: row;
  min-width: 100%;
  align-items: center;
  // padding-left: 20px;
  // padding-right: 10px;
  border-top: 1px solid #a0a0a0;
  background-color: white;
  padding: 4%;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hideScrollBar::-webkit-scrollbar {
  display: none !important;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hideScrollBar {
  overflow-y: scroll; /* Add the ability to scroll */
  -ms-overflow-style: none !important; /* IE and Edge */
  scrollbar-width: none !important; /* Firefox */
}

.checkContainer {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 14px;
  color: $primaryThemeDark;
  font-family: 'Avenir';
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 14px;
  width: 14px;
  background-color: #eee;
}

.checkContainer:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkContainer input:checked ~ .checkmark {
  background-color: #20a892;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkContainer input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkContainer .checkmark:after {
  left: 5.6px;
  top: 3.36px;
  width: 2.8px;
  height: 6.72px;
  border: solid white;
  border-width: 0 1.8px 1.8px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.radioButton {
  background-color: #20a892;
}
