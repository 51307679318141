@import "../../../variables.scss";

.tabItem {
  border-right-width: 1px;
  border-right-color: gray;
  border-right-style: solid;
  flex-grow: 1;
  text-align: center;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.closeModalBtn {
  position:absolute;
  top: 5px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
  padding: 4px 10px;
}

.closeModalBtn:hover {
  background-color: #acacac;
}

.row {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}

.rowLabel {
  color: $primaryColor;
  margin-right: 30px;
  width: 20%;
}

.textInput {
  border: 1px solid #d0d0d0;
  padding: 5px 20px;
  border-radius: 4px;
  width: 50%;
}

.patientModalEntryForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  background-color: #e8e8e8;
  border-radius: 10px;
  padding: 20px 20px;
  margin-top: 20px;
  position: relative;
}

.inviteRow {
  margin: 10px;
  display: flex;
  flex-direction:row;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.inviteName {
  width: 25%;
  text-align: center;
}

.inviteEmail {
  width: 25%;
  text-align: center;
}
.inviteDate {
  width: 25%;
  text-align: center;
}
.inviteStatus {
  width: 25%;
  text-align: center;
}
.warningIcon {
  color: red;
  font-size: 20px;
  cursor: pointer;
  margin: 0px 8px;
}
