@import '../../variables.scss';

.propIcon {
  color: $primaryThemeDark;
  font-size: 20px;
}

.propIcon1 {
  color: $primaryColorFaded;
  font-size: 20px;
}

.propIconWrapper {
  width: 20%;
}

.valuePropsWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.valuePropsBoxAila1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 10px;
  width: 20%;
  cursor: default;
}

.valuePropsBoxAila {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 10px;
  border-radius: 4px;
  border: 1px solid gray;
  cursor: pointer;
  width: inherit;
}

.valuePropsBoxAila:hover {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 10px;
  border-radius: 4px;
  border: 1px solid gray;
  background-color: $primaryThemeFadedDeep;
}

.valuePropsBoxCCFM {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 10px;
  border-radius: 4px;
  border: 1px solid gray;
  width: 400px;
  cursor: pointer;
}

.valuePropsBoxCCFM:hover {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 10px;
  border-radius: 4px;
  border: 1px solid gray;
  width: 400px;
  background-color: #c8c8c8;
}

.commImageWrapper {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  background: $primaryThemeDark;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalContent {
  width: 40%;
  height: 40%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, 50%);
  padding: 10px;
  position: absolute;
  background-color: white;
  left: 50%;
  bottom: 50%;
  border-radius: 4px;
}

.modalButtonDiv {
  display: flex;
  flex-direction: row;
  width: 80%;
  height: 22%;
  justify-content: space-evenly;
  position: relative;
}

@media only screen and (max-width: 600px) {
  .valuePropsWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 98%;
  }

  .commImageWrapper {
    width: 175px;
    height: 175px;
  }

  .modalContent {
    width: 95%;
    height: 30%;
  }

  .modalButtonDiv {
    height: 25%;
    width: 100%;
   }
}

.backModalBtn {
    position: absolute;
    top: 0px;
    left: 0px;
    font-size: 30px;
    cursor: pointer;
    padding: 4px 6px;
  }
  
  .backModalBtn:hover {
    background-color: #acacac;
  }


  .button2 {
    background-color: white;
    border-color: $primaryColor;
  }
  
  .button2:hover {
    background-color: $primaryColorFaded;
    border-color: $primaryColor;
  }
  
  .button2:disabled {
    background-color: $primaryColorFaded;
  }
  
  .button2:active {
    background-color: $primaryColor;
  }
  
  .button2:focus {
    background-color: $primaryColorFaded;
  }
  
  