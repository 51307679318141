@import "../../../variables.scss";

p{
  font-family: 'Avenir';
  word-wrap: break-word;
  overflow: hidden;
}

.selectWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-shadow: 3px 3px 3px 3px grey;
  width: 30%;
  border-radius: 5px;
  margin-bottom: 40px;
  background-color: #E8FAF6;
  // height: 700px;
}

.questionContainer {
  width: 70%;
}

.questionsFont {
  word-wrap: break-word;
  overflow: hidden;
  color: $primaryThemeDark;
  font-family: 'Avenir';
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;
}

.textInput1 {
  margin: 20px;
  width: 50%;
  border-width: 1px;
  border-color: #acacac;
  border-radius: 3px;
  border-style: solid;
}

.textInput2 {
  margin: 20px;
  width: 50%;
  border-width: 1px;
  border-color: gray;
  border-radius: 3px;
  border-style: solid;
  font-family: 'Avenir';
  padding-left: 4px;
}

.newDiseaseTextBox {
  margin: 20px;
  width: 40%;
  border-width: 1px;
  border-color: gray;
  border-radius: 3px;
  font-family: 'Avenir';
  background-color: #20A892;
}

.rangeSlider{
  margin: 20;
  margin-top: 60;
  margin-bottom: 60;
  width: '60%';
}

.radioButton {
  background-color: #20A892;
}

.active {
  background-color: red !important;
}

input[type='radio']:after {
  cursor: pointer;
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #e6f7e6;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid rgb(157, 194, 241);
}

input[type='radio']:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: $primaryColor;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid $primaryThemeDark;
}

.listContainer {
  background-color: #E8FAF6;
  width: 300px;
  padding: 0px;
}
.listItem {
  list-style-type: none;
  padding: 10px;
  margin: 2px;
  color: #637381;
}

.listItem:hover {
  background-color: #92DAD4;
}

.deleteIcon {
  color: #20A892;
  font-size: 16px;
  cursor: pointer;
  margin-left: 5px;
}

.checkContainer {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 18px;
  color: $primaryThemeDark;
  font-family: 'Avenir';
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

.checkContainer:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkContainer input:checked ~ .checkmark {
  background-color: #20A892;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkContainer input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkContainer .checkmark:after {
  left: 10px;
  top: 6px;
  width: 5px;
  height: 12px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
