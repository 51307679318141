@import '../../../variables';

.parentDiv {
  display: flex;
  flex-wrap: wrap;
  align-self: center;
  width: 60%;
  margin-bottom: 4vh;
  margin-top: 4vh;
  // flex-direction: column;
  justify-content: center;
}

.childDiv {
  flex: 1 0 21%;
  margin: 2vh 2vh;
}

.contentBackground {
  background-color: $contentBackground;
  border-radius: $contentBorderRadius;
  padding: 10px 20px;
  display: flex;
  width: 40%;
  margin: 3%;
}

.contentBackground:hover {
  background-color: $primaryThemeFadedDeep;
  border: 1px solid $primaryThemeDark;
  padding: 10px 20px;
  display: flex;
  width: 40%;
  margin: 3%;
}

.contentRow {
  display: flex;
  flex-direction: row;
  margin-top: 4px;
  align-items: center;
  border-bottom: 1px solid #d3d3d3;
  padding: 3px 0px;
}

@media only screen and (max-width: 600px) {
  .parentDiv {
    display: flex;
    flex-wrap: wrap;
    align-self: center;
    width: 98%;
    margin-bottom: 4vh;
    margin-top: 4vh;
    flex-direction: column;
    justify-content: center;
  }

  .contentBackground {
    background-color: $contentBackground;
    border-radius: $contentBorderRadius;
    padding: 10px 20px;
    display: flex;
    width: 80%;
    margin: 3%;
  }

  .contentBackground:hover {
    background-color: $primaryThemeFadedDeep;
    border: 1px solid $primaryThemeDark;
    padding: 10px 20px;
    display: flex;
    width: 80%;
    margin: 3%;
  }
}
