@import "../../../variables";

.infoRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin-top: 4px;
}

.infoKey {
  color: gray;
  width: 40%;
}

.infoValue {
  width: 60%;
  overflow-wrap: break-word;
}

.closeVideoBtn {
  position:absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
  padding: 4px 10px;
  background-color: $primaryColor;
  color: white;
  border-radius: 4px;
}

.PatientVideoName {
  position:absolute;
  top: 10px;
  left: 30px;
  font-size: 22px;
  cursor: pointer;
  padding: 4px 10px;
  color: $primaryColor;
  font-weight: bold;
  text-decoration: underline;
}

.openNotesBtn {
  position:relative;
  font-size: 16px;
  cursor: pointer;
  padding: 4px 10px;
  background-color: $primaryColor;
  color: white;
  border-radius: 4px;
  width: 50%;
  margin-top: 10px;
  text-align: center;
  // align-self: center;
}

.openNotesBtnCopy {
  font-size: 16px;
  cursor: pointer;
  padding: 4px 10px;
  background-color: $primaryColor;
  color: white;
  border-radius: 4px;
  width: 120px;
  text-align: center;
  margin-right: 20px;
}

.submitBtn {
  position:relative;
  // top: 10px;
  // right: 10px;
  font-size: 20px;
  cursor: pointer;
  padding: 4px 10px;
  background-color: $primaryColor;
  color: white;
  border-radius: 4px;
  // width: 60%;
}

.videoWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border-radius: $contentBorderRadius;
  width: 100%;
  margin-left: 10px;
  flex: 1;
  position: relative;
}

.audioVideoBtnWrapper:hover {
  background-color: #acacac;
}

.audioVideoBtnWrapper {
  margin: 10px;
  border: 1px solid $primaryColor;
  border-radius: 50%;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.audioVideoBtnWrapperActive {
  margin: 10px;
  border: 1px solid $primaryColor;
  border-radius: 50%;
  background-color: #acacac;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.closeModalBtn {
  position:absolute;
  top: 0px;
  right: 0px;
  font-size: 24px;
  cursor: pointer;
  padding: 4px 6px;
}

.closeModalBtn:hover {
  background-color: #acacac;
}

.modalRow {
  display: flex;
  flex-direction: row;
  width: 100%;
  // align-items: center;
  padding: 4px;
  position: relative;
}

.modalKey {
  width: 20%;
  color: gray;
}

.modalValue {
  width: 80%;
  overflow-wrap: break-word;
  margin-left: 4px;
  color: black;
}

.patientInfoWrapper {
  flex-direction: column;
  width: 250px;
  height: 100vh;
  overflow-y: scroll
}

video {
  width: 100%;
  height: 100%;
}

.modalNotesRow {
  display: flex;
  flex-direction: row;
  width: 100%;
  // align-items: center;
  padding: 4px;
  position: relative;
  margin-top: 20px;
}

.modalNotesCol {
  display: flex;
  flex-direction: column;
  width: 30%;
  // align-items: center;
  padding: 4px;
  position: relative;
  margin-left: 20px;
}

.textInput {
  border: 1px solid #d0d0d0;
  padding: 5px 20px;
  border-radius: 4px;
  width: 67%;
}

.modalNotesKey {
  color: $primaryColor;
  align-self: center;
  font-size: 20px;
}

.modalNotesQuestion {
  color: $primaryColor;
  align-self: center;
  font-size: 20px;
  width: 25%;
  margin-left: 30px;
}

.plusButton {
  color: $primaryColor;
  font-size: 35px;
  position: absolute;
  cursor: "pointer";
  right: 10px;
  top: 60px;
}

.modalValueValue {
  width: 100%;
  overflow-wrap: break-word;
  // margin-left: 4px;
  color: black;
}

.deleteIcon {
  color: #20A892;
  font-size: 20px;
  cursor: pointer;
  margin-left: 5px;
}

.addedNotesListContainer {
  // height: 130px;
  width: '100%';
  // overflow-y: auto;
  margin-top: 20px;
}

.flexible-modal {
  position: absolute;
  z-index: 1;
  border: 1px solid #ccc;
  background: white;
}
.flexible-modal-mask {
  position: fixed;
  height: 100%;
  background: rgba(55, 55, 55, 0.6);
  top:0;
  left:0;
  right:0;
  bottom:0;
}
.flexible-modal-resizer {
  position:absolute;
  right:0;
  bottom:0;
  cursor:se-resize;
  margin:5px;
  border-bottom: solid 2px #333;
  border-right: solid 2px #333;
}
.flexible-modal-drag-area{
  background: rgba(22, 22, 333, 0.2);
  height: 50px;
  position:absolute;
  right:0;
  top:0;
  cursor:move;
}
.appointmentNotesContainer{
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  //border: 3px solid #20A892;
  border-radius: 10px;
  padding: 20px;
  margin-right: 10px;
  margin-bottom: 40px;
}


.checkContainer {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  // font-size: large;
  color: rgb(23, 61, 68);
  font-family: 'Avenir';
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 10%;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
}

.checkContainer:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkContainer input:checked ~ .checkmark {
  background-color: #20a892;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkContainer input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkContainer .checkmark:after {
  left: 40%;
  top: 20%;
  width: 5px;
  height: 12px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
}

.rowLabel {
  color: $primaryColor;
  margin-right: 30px;
  width: 20%;
}