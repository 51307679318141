@import '../../../variables';

.propIcon {
  color: $primaryThemeDark;
  font-size: 20px;
}

.propIcon1 {
  color: $primaryColorFaded;
  font-size: 20px;
}

.propIconWrapper {
  width: 20%;
}

.parentDiv {
  display: flex;
  flex-wrap: wrap;
  align-self: center;
  justify-content: center;
  width: 80%;
  margin: auto;
}

.childDiv {
  flex: 1 0 21%;
  margin: 2vh 2vh;
  border-radius: 5px;
  border: 1px solid $primaryColor;
  max-width: 40%;
}

.valuePropsWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.valuePropsBoxAila {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 10px;
  // border-radius: 4px;
  // border: 1px solid gray;
  // cursor: pointer;
  width: inherit;
  align-self: flex-start;
}

.valueInputDivWrapper {
  display: flex;
  flex-direction: row;
  width: 80%;
  margin-top: 5vh;
  justify-content: center;
}

.valueInputBox {
  width: 50%;
  margin: 15px;
}



.checkContainer {
  display: block;
  position: relative;
  padding-left: 35px;
  // margin-bottom: 12px;
  margin: 0%;
  cursor: pointer;
  font-size: 18px;
  // color: $primaryThemeDark;
  font-family: 'Avenir';
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

.checkContainer:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkContainer input:checked ~ .checkmark {
  background-color: #20a892;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkContainer input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkContainer .checkmark:after {
  left: 10px;
  top: 6px;
  width: 5px;
  height: 12px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}