@import '../../../../variables';
$bottomInputWrapperHeight: 60px;

.sectionDivider {
  border-bottom: 1px solid $primaryColor;
  padding: 20px 30px;
  height: 300px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.rowIconText {
  color: white;
  font-weight: bold;
  font-size: 12px;
  text-align: center;
}

.rowIcon {
  flex-direction: column;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  cursor: pointer;
}

.sectionWrapper {
  margin-left: 40px;
  border-radius: 6px;
  background-color: #e8e8e8;
  width: 100%;
  height: 240px;
  padding: 10px 10px;
  overflow-y: scroll;
}

.closeModalBtn {
  position: absolute;
  top: 5px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
  padding: 4px 10px;
}

.closeModalBtn:hover {
  background-color: #acacac;
}

.contentBackground {
  background-color: $contentBackground;
  border-radius: $contentBorderRadius;
  padding: 10px 20px;
}

.contentRow {
  display: flex;
  flex-direction: row;
  margin-top: 4px;
  align-items: center;
  border-bottom: 1px solid #d3d3d3;
  padding: 3px 0px;
}

.carePlanRow {
  display: flex;
  flex-direction: row;
  // margin-top: 10px;
  align-items: center;
  // border-top: 1px solid #e8e8e8;
  padding: 3px 0px;
  justify-content: space-between;
}

.contentRowKey {
  color: gray;
  font-weight: bold;
  width: 160px;
}

.contentRowValue {
  margin-left: 10px;
}

.docImageWrapper {
  height: 200px;
  width: 200px;
  margin: 20px;
  border-radius: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.hoverChatWrapper {
  box-shadow: 5px 5px 5px 5px grey;
  height: 140px;
  width: 240px;
}

.textInput {
  margin-right: 10px;
  width: 85%;
  border: 1px solid #d0d0d0;
  padding: 5px 20px;
  margin-bottom: 20px;
  border-radius: 25px;
}

.chatBackground {
  background-color: $contentBackground;
  border-radius: $contentBorderRadius;
  padding: 10px 20px;
}

.messageTypeText {
  background-color: $contentBackground;
  border-radius: $contentBorderRadius;
}

.messagesWrapper {
  background-color: $contentBackground;
  border-radius: $contentBorderRadius $contentBorderRadius 0px 0px;
  padding: 10px 20px;
  display: flex;
  // flex-direction: column-reverse;
  overflow-y: scroll;
  height: 65vh;
  flex-direction: column;
}

.messageWrapperRight {
  float: right;
  display: flex;
  width: 80%;
  margin-block: 10px;
  // flex-direction: column-reverse;
}

.messageWrapperLeft {
  display: flex;
  width: 90%;
  // float: left;
  margin-block: 10px;
  flex-direction: row;
}

.messageLeft {
  background-color: $primaryThemeFadedDeep;
  padding: 8px 20px;
  border-radius: 10px;
  width: 100%;
}

.messageRight {
  width: 100%;
  background-color: $primaryThemeFadedDeep;
  padding: 8px 20px;
  border-radius: 10px;
}

.bottomInputWrapper {
  // position: absolute;
  height: $bottomInputWrapperHeight;
  display: flex;
  flex-direction: row;
  // width: 35%;
  align-items: center;
  border-top: 1px solid #a0a0a0;
  background-color: $contentBackground;
  justify-content: space-between;
  border-radius: 0px 0px $contentBorderRadius $contentBorderRadius;
  padding: 0% 2%;
}

.modalRow {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 4px;
  position: relative;
  margin-top: 30;
  justify-content: space-evenly;
  margin-top: 20px;
}

.modalCol {
  display: flex;
  flex-direction: column;
  width: 30%;
  padding: 4px;
  position: relative;
}

.modalKey {
  color: $primaryColor;
  align-self: center;
  font-size: 20px;
}

.modalValue {
  width: 100%;
  overflow-wrap: break-word;
  color: black;
}

.deleteIcon {
  color: #20a892;
  font-size: 20px;
  cursor: pointer;
  margin-left: 5px;
}

.addedNotesListContainer {
  height: 130px;
  width: '100%';
  overflow-y: auto;
  margin-top: 20px;
}
