@import '../../../../variables.scss';

.tabItem {
  border-right-width: 1px;
  border-right-color: gray;
  border-right-style: solid;
  flex-grow: 1;
  text-align: center;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.listWrapper {
  box-shadow: 5px 5px 5px 5px gray;
  border-width: 1px;
  border-color: gray;
  border-radius: 4px;
  width: 80%;
  overflow-y: scroll;
  padding: 4px 10px;
  overflow-x: hidden;
  min-height: 40vh;
  margin: auto;
}

.textInput {
  border: 1px solid #d0d0d0;
  padding: 5px 20px;
  border-radius: 4px;
  width: 50%;
}

.closeModalBtn {
  position: absolute;
  top: 5px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
  padding: 4px 10px;
}

.closeModalBtn:hover {
  background-color: #acacac;
}

.tabItem {
  border-right-width: 1px;
  border-right-color: gray;
  border-right-style: solid;
  flex-grow: 1;
  text-align: center;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.inviteEntryColumnHeader {
  text-align: center;
  font-weight: bold;
  color: $primaryThemeDark;
}

.inviteEntryColumn {
  text-align: center;
}

.row {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}

.rowLabel {
  color: $primaryColor;
  margin-right: 30px;
  width: 20%;
}

.patientModalEntryForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  background-color: #e8e8e8;
  border-radius: 10px;
  padding: 20px 20px;
  margin-top: 20px;
  position: relative;
}

.inviteRow {
  margin: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
}

.divider {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 10px;
}

.rowIcon {
  flex-direction: column;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  cursor: pointer;
}

.inviteListHeader {
  font-weight: bold;
  color: $primaryThemeDark;
  text-align: center;
}

.inviteListEntry {
  text-align: center;
}

.surveyQuestionForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #e8e8e8;
  border-radius: 10px;
  padding: 20px 20px;
  margin-top: 20px;
  position: relative;
  min-height: 140px;
}

.studyEntryRow {
  display: flex;
  flex-direction: row;
  width: 90%;
  margin-top: 14px;
  align-items: center;
}

.studyEntryKey {
  width: 200px;
  font-weight: bold;
  font-size: 16px;
}

.studyEntryInput {
  border: 1px solid #d0d0d0;
  padding: 5px 20px;
  border-radius: 4px;
  width: 60%;
}
