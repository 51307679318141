@import "../../../variables.scss";

.commImageWrapper {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  background: $primaryThemeDark;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 600px) {
  .commImageWrapper {
    width: 175px;
    height: 175px;
  }
}
