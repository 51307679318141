@import "../../variables.scss";

.container {
  background-color: #F3F3F3;
  min-height: 100vh;
  padding-bottom: 100px;
  overflow-x: hidden;
}

.button {
  background-color: $primaryColor;
}

.button:hover {
  background-color: $primaryColorFaded;
}

.button:disabled {
  background-color: $primaryColorFaded;
}

.loader {
  border: 16px solid #bcbcbc;
  border-top: 16px solid $primaryColor;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.supportEntryRow {
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
  width: 100%;
}

.supportTextInput {
  border: 1px solid #d0d0d0;
  padding: 5px 10px;
  border-radius: 4px;
  width: 50%;
}

.contentWrapper {
  margin: 15px;
  min-height: 100vh;
  background-color: white;
  border-radius: $contentBorderRadius;
  padding: 14px 20px 50px 20px;
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: $primaryColor;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin: 0 auto;
  box-shadow: inset 0px 0px 0px $primaryColor;
  animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%, 100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px $primaryColor;
  }
}

@media only screen and (max-width: 600px) {
  .loader {
    height: 80px;
    width: 80px;
  }
}
