.modal1Content {
  width: 600;
  height: 40vh;
  right: auto;
  bottom: auto;
  transform: translate(-50%, 50%);
  padding: 10px;
  position: absolute;
  background-color: white;
  left: 50%;
  bottom: 50%;
  border-radius: 4px;
}
.modal2Content {
  width: 50%;
  height: 60%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, 50%);
  padding: 10px;
  position: absolute;
  background-color: white;
  left: 50%;
  bottom: 50%;
  border-radius: 4px;
}
.heading {
  flex: 1;
  align-self: center;
  margin-top: -8vh;
  width: 100%;
}

@media only screen and (max-width: 600px) {
  .modal1Content {
    width: 95%;
    height: 85%;
  }
  .modal2Content {
    width: 95%;
    height: 85%;
  }
  .heading {
    flex: 1;
    align-self: center;
    margin-top: 1vh;
    width: 100%;
  }
}
