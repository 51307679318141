@import "../../../../variables.scss";

.row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.chartWrapper {
  width: 90%;
  box-shadow: 5px 5px 5px 5px grey;
  margin: 0 auto;
}

.rowIcon{
  flex-direction: column;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  cursor: pointer;
}

.chartTooltip {
  height: 100px;
  width: 200px;
  background-color: #FFFFFA;
  border: 1px solid gray;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.contentRow {
  display: flex;
  flex-direction: row;
  margin-top: 4px;
  align-items: center;
  border-bottom: 1px solid #e8e8e8;
  padding: 3px 0px;
}

.contentRowKey {
  color: gray;
  font-weight: bold;
  width: 180px;
}

.contentRowValue {
  margin-left: 10px
}

.headerHoverWrapper {
  box-shadow: 5px 5px 5px 5px grey;
}

.foodImage {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  cursor: pointer;
}

.closeModalBtn {
  position:absolute;
  top: 5px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
  padding: 4px 10px;
}

.closeModalBtn:hover {
  background-color: #acacac;
}

.symptomChartToolTipWrapper {
  background-color: white;
  box-shadow: 5px 5px 5px 5px grey;
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.symptomChartToolTipRow {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.symptomChartToolTipImage {
  width: 400px;
  height: 400px;
  border-radius: $contentBorderRadius;
  background: transparent url("https://i.giphy.com/media/3oEjI6SIIHBdRxXI40/200w.gif") no-repeat scroll center center;
}
.plusButtonNormal {
  color: $primaryColor;
  font-size: 35px;
  position: relative;
  cursor: pointer;
  // right: 10px;
  // top: 55px;
}
.tableHeader {
  font-weight: bold;
  width: 25%;
  text-align: center;
  color: gray;
}
