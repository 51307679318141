@import '../../../variables';

.infoRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin-top: 4px;
}

.infoKey {
  color: gray;
  width: 40%;
}

.infoValue {
  width: 60%;
  overflow-wrap: break-word;
}

.submitBtn {
  position: relative;
  // top: 10px;
  // right: 10px;
  font-size: 20px;
  cursor: pointer;
  padding: 4px 10px;
  background-color: $primaryColor;
  color: white;
  border-radius: 4px;
  // width: 60%;
}

.mainContentWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 10px;
  background-color: white;
  border-radius: $contentBorderRadius;
  width: 100%;
  margin-left: 10px;
  flex: 1;
  position: relative;
  min-height: 100vh;
}

.patientInfoWrapper {
  flex-direction: column;
  width: 250px;
  height: 100vh;
  overflow-y: scroll;
}

.deleteIcon {
  color: #20a892;
  font-size: 20px;
  cursor: pointer;
  margin-left: 5px;
}

.rowIconText {
  color: white;
  font-weight: bold;
  font-size: 12px;
  text-align: center;
}

.rowIcon {
  flex-direction: column;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  cursor: pointer;
}

.listWrapper {
  box-shadow: 5px 5px 5px 5px gray;
  border-width: 1px;
  border-color: gray;
  border-radius: 4px;
  width: 100%;
  overflow-y: scroll;
  padding: 4px 10px;
  overflow-x: hidden;
  min-height: 550px;
}

.inviteListHeader {
  font-weight: bold;
  color: $primaryColor;
  text-align: center;
}

.inviteListEntry {
  text-align: center;
}

.inviteRow {
  margin: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
}

.closeModalBtn {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 24px;
  cursor: pointer;
  padding: 4px 10px;
}

.closeModalBtn:hover {
  background-color: #acacac;
}

.patientDataRow {
  display: flex;
  flex-direction: row;
  margin-top: 4px;
  align-items: center;
  border-bottom: 1px solid #e8e8e8;
  padding: 3px 0px;
  min-height: 40px;
}

.patientDataRowKey {
  color: gray;
  font-weight: bold;
  width: 160px;
}

.patientDataRowValue {
  margin-left: 10px;
}

.patientContentBackground {
  background-color: $contentBackground;
  border-radius: $contentBorderRadius;
  padding: 10px 20px;
}

.textInput {
  border: 1px solid #d0d0d0;
  padding: 5px 20px;
  border-radius: 4px;
  width: 100%;
}

.labTextInput {
  width: 94%;
  border: 1px solid #d0d0d0;
  padding: 5px 20px;
  margin-bottom: 20px;
  border-radius: 2px;
}

.erxContainer {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.horizontalLine {
  border-bottom: 1px solid $primaryColor;
  width: 95%;
  margin: 0 auto;
}

.erxInputFields {
  border: 1px solid #d0d0d0;
  padding: 5px 10px;
  border-radius: 4px;
  width: 200px;
}

.erxInstructionField {
  border: 1px solid #d0d0d0;
  padding: 5px 10px;
  border-radius: 4px;
  width: 50%;
}

.row {
  display: flex;
  flex-direction: row;
  width: 100%;
  border-bottom: 1px solid #c8c8c8;
  padding: 6px 0px;
}

.headerText {
  font-weight: bold;
  text-align: center;
  color: gray;
}

.entryText {
  text-align: center;
}

.inputDiv {
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  width: 100%;
  margin-bottom: 15px;
  justify-content: flex-start;
}

.inputQuestion {
  align-self: left;
  color: $primaryColor;
  font-weight: bold;
  //   width: 25%;
}

.searchAdded {
  // font-size: 18px;
  margin-top: 20;
  margin-left: 30;
  color: $primaryThemeDark;
}


.checkContainer {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  // font-size: large;
  color: rgb(23, 61, 68);
  font-family: 'Avenir';
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 10%;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: lightgray;
}

.checkContainer:hover input~.checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkContainer input:checked~.checkmark {
  background-color: #20a892;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkContainer input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkContainer .checkmark:after {
  left: 40%;
  top: 20%;
  width: 5px;
  height: 12px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}


// .checkContainer {
//   display: block;
//   position: relative;
//   padding-left: 35px;
//   margin-bottom: 12px;
//   cursor: pointer;
//   font-size: 14px;
//   color: $primaryThemeDark;
//   font-family: 'Avenir';
//   -webkit-user-select: none;
//   -moz-user-select: none;
//   -ms-user-select: none;
//   user-select: none;
// }

// .checkContainer input {
//   position: absolute;
//   opacity: 0;
//   cursor: pointer;
//   height: 0;
//   width: 0;
// }

// .checkmark {
//   position: absolute;
//   top: 0;
//   left: 0;
//   height: 14px;
//   width: 14px;
//   background-color: #eee;
// }

// .checkContainer:hover input ~ .checkmark {
//   background-color: #ccc;
// }

// /* When the checkbox is checked, add a blue background */
// .checkContainer input:checked ~ .checkmark {
//   background-color: #20a892;
// }

// /* Create the checkmark/indicator (hidden when not checked) */
// .checkmark:after {
//   content: '';
//   position: absolute;
//   display: none;
// }

// /* Show the checkmark when checked */
// .checkContainer input:checked ~ .checkmark:after {
//   display: block;
// }

// /* Style the checkmark/indicator */
// .checkContainer .checkmark:after {
//   left: 5.6px;
//   top: 3.36px;
//   width: 2.8px;
//   height: 6.72px;
//   border: solid white;
//   border-width: 0 1.8px 1.8px 0;
//   -webkit-transform: rotate(45deg);
//   -ms-transform: rotate(45deg);
//   transform: rotate(45deg);
// }

.radioButton {
  background-color: #20A892;
}

.contentBackground1 {
  background-color: $contentBackground;
  border-radius: $contentBorderRadius;
  padding: 10px 20px;
}

.tabContentWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  width: 100%;
  padding: 0 20px;
}

.contentBackground {
  background-color: $contentBackground;
  border-radius: $contentBorderRadius;
  padding: 10px 20px;
}

.contentRow {
  display: flex;
  flex-direction: row;
  margin-top: 4px;
  align-items: center;
  border-bottom: 1px solid #e8e8e8;
  padding: 3px 0px;
  min-height: 40px;
}

.tableHeader {
  font-weight: bold;
  width: 25%;
  text-align: center;
  color: gray;
}

.tooltip {
  // width: 600px;
  white-space: normal;
}