.fc-timeGrid-view.fc-timeGridWeek-view {
  display: flex;
}

.fc-timeGrid-view.fc-timeGridWeek-view .fc-time-grid {
  flex: 0 0 auto;
}

.fc-timeGrid-view.fc-timeGridWeek-view .fc-slats {
  flex: 1 1 auto;
}

.fc {
    max-width: 1100px;
    margin: 0 auto;
}

.fc-direction-ltr .fc-timegrid-slot-label-frame {
    /* padding-right: 0.75em; */
    text-align: left;
}
