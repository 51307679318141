@import "../../../variables.scss";

p{
  font-family: 'Avenir';
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;
}

.textInput1 {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 49%;
  border-width: 1px;
  border-color: #acacac;
  border-style: solid;
  border-radius: 3px;
  font-family: 'Avenir';
}

.textInput2 {
  margin: 20px;
  width: 50%;
  border-width: 1px;
  border-color: #acacac;
  border-radius: 3px;
  border-style: solid;
  font-family: 'Avenir';
}

.tabItem {
  border-right-width: 1px;
  border-color: $primaryColorFaded;
  border-right-style: solid;
  flex-grow: 1;
  text-align: center;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-family: 'Avenir';
}

