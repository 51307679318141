@import '../../../variables.scss';

.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 1%;
}
.subContainer {
  display: flex;
  flex-direction: column;
  width: 55%;
  align-items: center;
  margin: 0 auto;
}

.heading {
  font-size: 30px;
  margin: auto;
  text-align: center;
  font-weight: bold;
  color: $primaryThemeDark;
}

.mainField {
  display: flex;
  flex-direction: row;
  width: 80%;
  margin-top: 7vh;
  justify-content: center;
}

.subField {
  width: 50%;
  margin: 5%;
}

.button {
  flex-direction: row;
  align-items: center;
  margin-top: 5vh;
  align-items: center;
  width: 15vw;
  background-color: $primaryColor;
  border-color: $primaryColor;
}

.button:hover {
  background-color: $primaryColorFaded;
  border-color: $primaryColor;
}

.button:disabled {
  background-color: $primaryColorFaded;
}

.button:active {
  background-color: $primaryColor;
}

.button:focus {
  background-color: $primaryColor;
}

.logo {
  width: 4vh;
  height: 4vh;
  align-self: flex-start;
}

.checkContainer {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 14px;
  color: $primaryThemeDark;
  font-family: 'Avenir';
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 14px;
  width: 14px;
  background-color: #eee;
}

.checkContainer:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkContainer input:checked ~ .checkmark {
  background-color: #20a892;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkContainer input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkContainer .checkmark:after {
  left: 5.6px;
  top: 3.36px;
  width: 2.8px;
  height: 6.72px;
  border: solid white;
  border-width: 0 1.8px 1.8px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

@media only screen and (max-width: 600px) {
  .subContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 98%;
  }

  .button {
    background-color: $primaryColor;
    border-color: $primaryColor;
    flex-direction: row;
    align-items: center;
    margin-top: 5vh;
    align-items: center;
    width: 50vw;
  }

  .mainField {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin-top: 2vh;
    justify-content: center;
    align-items: center;
  }

  .subField {
    width: 70%;
    margin: 5%;
  }
}
