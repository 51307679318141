@import '../../../../variables.scss';

.badgeRed {
  padding: 3px 6px;
  font-size: 9px;
  letter-spacing: 1px;
  border-radius: 15px;
  color: #ffffff;
  background: #d63030;
}

.badgeYellow {
  padding: 3px 6px;
  font-size: 9px;
  letter-spacing: 1px;
  border-radius: 15px;
  color: #000000;
  background: #ffd331;
}

.badgeGray {
  padding: 3px 6px;
  font-size: 9px;
  letter-spacing: 1px;
  border-radius: 15px;
  color: #ffffff;
  background: #777777;
}

.sidebarBtnWrapper {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.sidebarBtn {
  padding: 1px 15px;
  border-radius: 40px;
  background: rgba(255, 255, 255, 0.05);
  color: #adadad;
  text-decoration: none;
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 10px;
}

.patientInfoStripWrapperCollapsed {
  min-width: 100%;
  border-bottom: 1px solid $primaryColor;
  display: flex;
  flex-direction: column;
}

.patientInfoStripWrapperExpanded {
  min-width: 100%;
  padding-left: 20px;
  border-bottom: 1px solid $primaryColor;
  display: flex;
  flex-direction: column;
  padding-right: 40px;
  height: 220px;
  -webkit-animation: expand 2s;
  animation: expand 2s;
}

.patientInfoStripWrapper {
  min-width: 100%;
  padding-left: 20px;
  border-bottom: 1px solid $primaryColor;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 40px;
}

.patientInfoWrapper {
  width: 20%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  overflow-y: hidden;
}

.conditionsWrapper {
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
}

.medicationsWrapper {
  overflow-y: hidden;
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.patientContactWrapper {
  width: 30%;
  height: 100%;
  margin: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contactImageWrapper {
  width: 30%;
  padding-bottom: 20px;
  height: 100%;
  margin: 4px;
  align-items: flex-end;
  justify-content: space-evenly;
  display: flex;
}

.activeLink {
  color: red;
}

.patientStripIcons {
  color: #20a892;
  font-size: 30px;
  cursor: pointer;
}

.modalContentWrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
}

.closeModalBtn {
  position: absolute;
  top: 5px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
  padding: 4px 10px;
}

@keyframes expand {
  0% {
    height: 80px;
  }
  100% {
    height: 200px;
  }
}

@-webkit-keyframes expand {
  0% {
    height: 80px;
  }
  100% {
    height: 200px;
  }
}

@keyframes collapse {
  0% {
    height: 200px;
  }
  100% {
    height: 80px;
  }
}

@-webkit-keyframes collapse {
  0% {
    height: 200px;
  }
  100% {
    height: 80px;
  }
}

@keyframes imageExpand {
  0% {
    height: 60px;
    width: 60px;
  }
  100% {
    height: 100px;
    width: 100px;
  }
}

@-webkit-keyframes imageExpand {
  0% {
    height: 60px;
    width: 60px;
  }
  100% {
    height: 100px;
    width: 100px;
  }
}

@keyframes iconsExpand {
  0% {
    width: 200px;
  }
  100% {
    width: 240px;
  }
}

@-webkit-keyframes iconsExpand {
  0% {
    width: 200px;
  }
  100% {
    width: 240px;
  }
}

@keyframes logoExpand {
  0% {
    height: 60px;
    width: 60px;
  }
  100% {
    height: 100px;
    width: 100px;
  }
}

@-webkit-keyframes logoExpand {
  0% {
    height: 60px;
    width: 60px;
  }
  100% {
    height: 100px;
    width: 100px;
  }
}

.logo {
  height: 60px;
  width: 60px;
}

.logoAnimated {
  height: 100px;
  width: 100px;
  -webkit-animation: logoExpand 2s;
  animation: logoExpand 2s;
}

.iconsWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 200px;
  margin-right: 20px;
  justify-content: space-between;
}

.iconsWrapperAnimated {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 240px;
  position: absolute;
  right: 15px;
  bottom: 2px;
  justify-content: space-between;
  -webkit-animation: iconsExpand 2s;
  animation: iconsExpand 2s;
}

.imageWrapper {
  height: 60px;
  width: 60px;
  border-radius: 50%;
}

.imageAnimationWrapper {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  -webkit-animation: imageExpand 2s;
  animation: imageExpand 2s;
}

.expandedWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 5px 14px;
  height: 200px;
  -webkit-animation: expand 2s;
  animation: expand 2s;
  background-color: white;
}

.collapsedWrapper {
  background-color: white;
  display: flex;
  flex-direction: row;
  padding: 0px 14px;
  align-items: center;
  height: 80px;
}

.conditionsMedicationWrapper {
  width: 80%;
  display: flex;
  flex-direction: row;
  padding-top: 10px;
  height: 100%;
  justify-content: space-around;
}

.subMenuItemWhite {
  color: white;
}

.subMenuItemBlack {
  color: black;
}

.searchModalContentWrapper {
  height: 100%;
  width: 100%;
  padding-top: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.searchElementsWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 20px;
}

.searchResultWrapper {
  background-color: #e8e8e8;
  padding: 10px;
  width: 100%;
  border-radius: $contentBorderRadius;
  cursor: pointer;
}

.selectedLabsWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  max-height: 30%;
  overflow-y: scroll;
  padding: 10px;
}

.selectedLabsTextWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 0px 10px;
}

.selectedLabEntry {
  padding: 4px 20px;
  background-color: $primaryColorFaded;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: $contentBorderRadius;
  margin: 5px;
}

.selectedLabEntryDelete {
  color: white;
  margin-left: 20px;
  cursor: pointer;
}

.row {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 10px;
  margin-left: 60px;
}

.rowLabel {
  color: $primaryColor;
  // margin-right: 30px;
  width: 20%;
}

.remote-media {
  video {
    height: 160px;
  }
}

.audioVideoBtnWrapper:hover {
  background-color: #acacac;
}

.audioVideoBtnWrapper {
  margin: 10px;
  border: 1px solid $primaryColor;
  border-radius: 50%;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.audioVideoBtnWrapperActive {
  margin: 10px;
  border: 1px solid $primaryColor;
  border-radius: 50%;
  background-color: #acacac;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.parentDiv {
  display: flex;
  flex-wrap: wrap;
  align-self: center;
  width: 100%;
  margin-bottom: 4vh;
  margin-top: 4vh;
}

.childDiv {
  flex: 1 0 21%;
  margin: 2vh 2vh;
}

.button2 {
  background-color: white;
  border-color: $primaryColor;
}

.button2:hover {
  background-color: $primaryColorFaded;
  border-color: $primaryColor;
}

.button2:disabled {
  background-color: $primaryColorFaded;
}

.button2:active {
  background-color: $primaryColor;
}

.button2:focus {
  background-color: $primaryColorFaded;
}
