@import "../../../variables.scss";
.custom-file-input::-webkit-file-upload-button {
    visibility: hidden;
  }
  .custom-file-input::before {
    content: 'Select some files';
    display: inline-block;
    background: linear-gradient(top, #f9f9f9, #e3e3e3);
    border: 1px solid #999;
    border-radius: 3px;
    padding: 5px 8px;
    outline: none;
    white-space: nowrap;
    cursor: pointer;
    text-shadow: 1px 1px #fff;
    font-weight: 700;
    font-size: 10pt;
  }
  .custom-file-input:hover::before {
    border-color: black;
  }
  .custom-file-input:active::before {
    background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
  }
  
  .closeModalBtn {
    position:absolute;
    top: 5px;
    right: 10px;
    font-size: 24px;
    cursor: pointer;
    padding: 4px 10px;
  }
  
  .closeModalBtn:hover {
    background-color: #acacac;
  }
  .tabItem {
    border-right-width: 1px;
    border-right-color: gray;
    border-right-style: solid;
    flex-grow: 1;
    text-align: center;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .warningIcon {
    color: red;
    font-size: 20px;
    cursor: pointer;
    margin: 0px 8px;
  }
  .nameWrapper {
    // height: 80px;
    display: flex;
    flex-direction: column;
    min-width: 20%;
    // border-bottom-style: solid;
    // border-bottom-width: 1px;
    // border-color: #e8e8e8;
    // padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 20px;
  }

  .contentBackground {
    background-color: $contentBackground;
    border-radius: $contentBorderRadius;
    width: 73vw;
    padding: 10px 10px;
    white-space: nowrap;
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: 70vh;
    margin-top: 30px;
  }

  .contentRow {
    margin-top: 4px;
    align-items: center;
    // border-bottom: 1px solid #e8e8e8;
    padding: 10px 0px;
    // width: 197%;
    // min-height: 50px;
  }
  
  .tableHeader {
    font-weight: bold;
    width: 25%;
    text-align: center;
    color: gray;
    display:inline-block;
    word-wrap: break-word;
    white-space: normal;
    padding: 0px 5px;
    // border-bottom: 1px solid #e8e8e8;
  }
  .smtableHeader {
    font-weight: bold;
    width: 100px;
    text-align: center;
    color: gray;
    display:inline-block;
    word-wrap: break-word;
    white-space: normal;
    padding: 0px 5px;
    // border-bottom: 1px solid #e8e8e8;
  }
  .mtableHeader {
    font-weight: bold;
    width: 200px;
    text-align: center;
    color: gray;
    display:inline-block;
    word-wrap: break-word;
    white-space: normal;
    padding: 0px 5px;
    // border-bottom: 1px solid #e8e8e8;
  }
  .xtableHeader {
    font-weight: bold;
    width: 230px;
    text-align: center;
    color: gray;
    display:inline-block;
    word-wrap: break-word;
    white-space: normal;
    padding: 0px 5px;
    // border-bottom: 1px solid #e8e8e8;
  }

  .smtableData {
    width: 100px;
    text-align: center;
    display:inline-block;
    word-wrap: break-word;
    white-space: normal;
    padding: 0px 5px;
    // border-bottom: 1px solid #e8e8e8;
  }
  .mtableData {
    width: 200px;
    text-align: center;
    display:inline-block;
    word-wrap: break-word;
    white-space: normal;
    padding: 0px 5px;
    // border-bottom: 1px solid #e8e8e8;
  }
  .xtableData {
    width: 230px;
    text-align: center;
    display:inline-block;
    word-wrap: break-word;
    white-space: normal;
    padding: 0px 5px;
    // border-bottom: 1px solid #e8e8e8;
  }
  .tooltip {
    width: 400px;
    white-space : normal;
  }