@import '../../../variables.scss';
.tabItem {
  border-right-width: 1px;
  border-right-color: gray;
  border-right-style: solid;
  flex-grow: 1;
  text-align: center;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.textInput {
  border: 1px solid #d0d0d0;
  padding: 5px 10px;
  border-radius: 4px;
  width: 50%;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
}

.rowLabel {
  color: $primaryColor;
  margin-right: 30px;
  width: 20%;
}

.infoWrapper {
  width: 100%;
  box-shadow: 5px 5px 5px 5px grey;
  padding: 10px 40px;
  border-radius: 5px;
}

.closeModalBtn {
  position: absolute;
  top: 5px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
  padding: 4px 10px;
}

.closeModalBtn:hover {
  background-color: #acacac;
}

.subRowLabel {
  color: $primaryColor;
  margin-right: 30px;
}

// .checkContainer {
//   display: block;
//   position: relative;
//   padding-left: 35px;
//   margin-bottom: 12px;
//   cursor: pointer;
//   // font-size: 14px;
//   color: $primaryThemeDark;
//   font-family: 'Avenir';
//   -webkit-user-select: none;
//   -moz-user-select: none;
//   -ms-user-select: none;
//   user-select: none;
// }

// .checkContainer input {
//   position: absolute;
//   opacity: 0;
//   cursor: pointer;
//   height: 0;
//   width: 0;
// }

// .checkmark {
//   position: absolute;
//   top: 0;
//   left: 0;
//   height: 14px;
//   width: 14px;
//   background-color: #eee;
// }

// .checkContainer:hover input ~ .checkmark {
//   background-color: #ccc;
// }

// /* When the checkbox is checked, add a blue background */
// .checkContainer input:checked ~ .checkmark {
//   background-color: #20a892;
// }

// /* Create the checkmark/indicator (hidden when not checked) */
// .checkmark:after {
//   content: '';
//   position: absolute;
//   display: none;
// }

// /* Show the checkmark when checked */
// .checkContainer input:checked ~ .checkmark:after {
//   display: block;
// }

// /* Style the checkmark/indicator */
// .checkContainer .checkmark:after {
//   left: 5.6px;
//   top: 3.36px;
//   width: 2.8px;
//   height: 6.72px;
//   border: solid white;
//   border-width: 0 1.8px 1.8px 0;
//   -webkit-transform: rotate(45deg);
//   -ms-transform: rotate(45deg);
//   transform: rotate(45deg);
// }

.addManualSelectInput {
  padding: 5px 20px;
  border-radius: 4px;
  width: 80%;
}

.checkContainer {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  // font-size: large;
  color: rgb(23, 61, 68);
  font-family: 'Avenir';
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 10%;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
}

.checkContainer:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkContainer input:checked ~ .checkmark {
  background-color: #20a892;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkContainer input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkContainer .checkmark:after {
  left: 40%;
  top: 20%;
  width: 5px;
  height: 12px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
