@import '../../styles/global.module';

.sectionDivider {
    border-bottom: 1px solid $primaryColor;
    padding: 30px 0px;
    min-height: 350px;
}

.rowIcon{
    flex-direction: column;
    height: 100px;
    width: 100px;
    border-radius: 50%;
    background-color: $primaryThemeDark;
    display: flex;
    align-items: center;
    justify-content: center;
}

.rowIconText {
    color: white;
    font-weight: bold;
    font-size: 14px;
}

.graphRow {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 20px;
    flex-wrap: wrap;
    justify-content: space-around;
}

.graphWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-shadow: 5px 5px 5px 5px grey;
    width: 45%;
    border-radius: 5px;
    margin-bottom: 40px;
}

.tableWrapper {
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-shadow: 5px 5px 5px 5px grey;
    margin: 20px;
    padding-bottom: 25px;
    border-radius: 5px;
}

.tableWrapper2 {
    border-radius: 5px;
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-shadow: 5px 5px 5px 5px grey;
    margin: 20px;
    padding-bottom: 10px;
}

.changeWrapper {
    width: 70%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

}

.hoverChatWrapper {
    box-shadow: 5px 5px 5px 5px grey;
    width: 500px;
}

.tabItem {
    border-right-width: 1px;
    border-right-color: gray;
    border-right-style: solid;
    flex-grow: 1;
    text-align: center;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.closeModalBtn {
    position:absolute;
    top: 5px;
    right: 10px;
    font-size: 24px;
    cursor: pointer;
    padding: 4px 10px;
}

.closeModalBtn:hover {
    background-color: #acacac;
}

.textInput2 {
    border: 1px solid #d0d0d0;
    padding: 5px 10px;
    border-radius: 4px;
    width: 100%;
}

.row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
}

.rowLabel {
    color: $primaryColor;
    margin-right: 30px;
    width: 20%;
}

.textInput {
    border: 1px solid #d0d0d0;
    padding: 5px 20px;
    border-radius: 4px;
    width: 50%;
}

.surveyChartTooltip {
    height: 100px;
    width: 200px;
    background-color: #FFFFFA;
    border: 1px solid gray;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
