@import "../../../variables";

.tabItem {
  border-right-width: 1px;
  border-right-color: gray;
  border-right-style: solid;
  flex-grow: 1;
  text-align: center;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.tabWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.searchView {
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.searchInput {
  width: 300px;
  border: 1px solid #d0d0d0;
  padding: 5px 20px;
  border-radius: 4px;
}

.searchWrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.zipTxt {
  font-size: 18px;
  margin-bottom: 10px;
}

.searchResultsWrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  height: 500px;
  overflow-y: scroll;
}

.searchItem {
  position: relative;
  width: 60%;
  height: 140px;
  border: 1px solid #d0d0d0;
  margin-bottom: 10px;
  padding: 10px 10px;
  background-color: $contentBackground;
  border-radius: 4px;
  cursor: pointer;
}

.hoverWrapper {
  box-shadow: 5px 5px 5px 5px grey;
}

.modalWrapper {
  height: 100%;
  width: 100%;
  position: relative;
}

.timeWrapper {
  border: 1px solid #d0d0d0;
  border-radius: 4px;
  width: 100px;
  text-align: center;
  margin-right: 10px;
  cursor: pointer;
}

.timeViewWrapper {
  margin-top: 20px;
}
