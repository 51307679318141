@import "../../../variables.scss";

p{
  font-family: 'Avenir';
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;
}

.textInput1 {
  margin: 20px;
  width: 50%;
  border-width: 1px;
  border-color: #acacac;
  border-radius: 3px;
  border-style: solid;
}

.textInput2 {
  margin: 20px;
  width: 50%;
  border-width: 2px;
  border-color: $primaryColorFaded;
  border-radius: 3px;
  border-style: solid;
  font-family: 'Avenir';
}

.radioButton {
  background-color: #20A892;
}

.active {
  background-color: red !important;
}

input[type='radio']:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #e6f7e6;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid rgb(157, 194, 241);
}

input[type='radio']:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: $primaryColor;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid $primaryThemeDark;
}
