@import "../../../../../variables";

.tabItem {
  border-right-width: 1px;
  border-right-color: gray;
  border-right-style: solid;
  flex-grow: 1;
  text-align: center;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.row {
  display: flex;
  flex-direction: row;
  width: 100%;
  border-bottom: 1px solid #c8c8c8;
  padding: 6px 0px;
}

.headerText {
  font-weight: bold;
  text-align: center;
  color: gray;
}

.entryText {
  text-align: center;
}

.closeModalBtn {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 24px;
  cursor: pointer;
  padding: 4px 10px;
}

.closeModalBtn:hover {
  background-color: #acacac;
}

.labTextInput {
  width:94%;
  border: 1px solid #d0d0d0;
  padding: 5px 20px;
  margin-bottom: 20px;
  border-radius: 2px;
}

.plusButton {
  color: $primaryColor;
  font-size: 35px;
  position: relative;
  cursor: pointer;
  right: 10px;
  // top: 55px;
}

.addManualRow {
  display: flex;
  flex-direction: row;
  width: 100%;
  // align-items: center;
  padding: 4px;
  position: relative;
  margin-top: 20px;
}

.addManualQuestion {
  color: $primaryColor;
  align-self: center;
  font-size: 20px;
  width: 25%;
  margin-left: 30px;
}

.addManualTextInput {
  border: 1px solid #d0d0d0;
  padding: 5px 20px;
  border-radius: 4px;
  width: 70%;
  // height: 40px;
}