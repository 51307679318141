@import '../../../variables.scss';

.textInput {
  border: 1px solid #d0d0d0;
  padding: 5px 10px;
  border-radius: 4px;
  width: 50%;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  justify-content: center;
}

.rowLabel {
  color: $primaryColor;
  margin-right: 30px;
  width: 20%;
  font-size: 20px;
}

.rowAnswer {
  width: 50%;
}

.infoWrapper {
  width: 100%;
  box-shadow: 5px 5px 5px 5px grey;
  padding: 10px 40px;
  border-radius: 5px;
}

.closeModalBtn {
  position: absolute;
  top: 5px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
  padding: 4px 10px;
}

.closeModalBtn:hover {
  background-color: #acacac;
}

.subRowLabel {
  color: $primaryColor;
  margin-right: 30px;
}

.subContentWrapper {
  width: 65%;
  padding: 20px 40px;
}

@media only screen and (max-width: 600px) {
  .subContentWrapper {
    width: 100%;
    padding: 0px;
  }

  .row {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    justify-content: center;
  }

  .rowLabel {
    color: $primaryColor;
    // margin-right: 30px;
    margin: auto;
    width: 100%;
    font-size: 20px;
    text-align: center;
    margin: 1%;
  }

  .rowAnswer {
    width: 80%;
  }
}
