@import '../../../../variables.scss';
$bottomInputWrapperHeight: 60px;

.userChatEntry {
  border-bottom: 1px solid #e8e8e8;
}

.userChatEntryActive {
  background: #20a89244;
}

.textInput {
  margin-right: 10px;
  width: 94%;
  border: 1px solid #d0d0d0;
  padding: 5px 20px;
  margin-bottom: 20px;
  border-radius: 25px;
}

.nameWrapper {
  height: 80px;
  display: flex;
  flex-direction: column;
  min-width: 100%;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #e8e8e8;
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 20px;
}

.bottomInputWrapper {
  position: absolute;
  bottom: 120px;
  height: 10%;
  display: flex;
  flex-direction: row;
  min-width: 100%;
  align-items: center;
  padding-left: 20px;
  padding-right: 10px;
  border-top: 1px solid #a0a0a0;
  background-color: white;
}

.messagesWrapper {
  min-width: 100%;
  // display: flex;
  flex-direction: column;
  padding: 10px 20px;
  // overflow-y: scroll;
  overflow-x: hidden;

  display: flex;
  // flex-direction: column-reverse;
  overflow-y: scroll;
  margin-bottom: 5%;
  height: 100%;
}

.messageWrapperRight {
  float: right;
  display: flex;
  flex-direction: row;
  width: 80%;
}

.messageWrapperLeft {
  display: flex;
  flex-direction: row;
  width: 80%;
}

.messageLeft {
  background-color: #e8e8e8;
  padding: 8px 20px;
  border-radius: 10px;
  width: 100%;
}

.messageRight {
  width: 100%;
  background-color: $primaryThemeFadedDeep;
  padding: 8px 20px;
  border-radius: 10px;
}

.closeVideoBtn {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
  padding: 4px 10px;
  background-color: $primaryColor;
  color: white;
  border-radius: 4px;
}

.closeModalBtn {
  position: absolute;
  top: 5px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
  padding: 4px 10px;
}

.closeModalBtn:hover {
  background-color: #acacac;
}

.videoWrapperSmall {
  height: 60px;
  width: 60px;
  margin: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.videoWrapperBig {
  height: 200px;
  width: 200px;
  margin: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.tabItem {
  border-right-width: 1px;
  border-right-color: gray;
  border-right-style: solid;
  flex-grow: 1;
  text-align: center;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
