@import 'variables.scss';

$theme-colors: (
  'info': $primaryColor,
  'danger': red,
);
$sidebar-bg-color: $primaryColor;
$sidebar-color: #ffffff !default;
$submenu-bg-color: $primaryColorFaded !default;
$submenu-bg-color-collapsed: $primaryColor !default;
$icon-bg-color: #acacac !default;
$highlight-color: white !default;
$fancy-field-editable-border-color: $primaryColor !default;
$fancy-field-label-color: $primaryColor !default;
$fancy-field-typeahead-focus-color: black !default;
$fancy-field-typeahead-focus-text-color: black !default;
$fancy-field-typeahead-bg-color: white !default;
$event-bg: $primaryColor !default;
$event-border: darken($primaryColor, 0%) !default;
$event-outline: $primaryColor !default;
$today-highlight-bg: $primaryThemeFadedDeep !default;

@import '~react-toastify/dist/ReactToastify.css';
@import '~react-pro-sidebar/dist/scss/styles.scss';
@import '~react-fancy-field/dist/FancyField.scss';
@import '~bootstrap/scss/bootstrap';
@import '~react-big-calendar/lib/sass/styles';
@import '~react-big-calendar/lib/addons/dragAndDrop/styles';
@import '~react-calendar/dist/Calendar.css';
@import '~react-date-picker/dist/DatePicker.css';

.ReactCollapse--collapse {
  transition: height 500ms;
}

.App {
  text-align: center;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

.ReactModal__Body--open {
  overflow-y: hidden;
}

.Toastify__toast--success {
  background: $primaryColor;
}

.btn-primary {
  background-color: white;
}


.rce-citem {
  background: #00000000 !important;
}

.rce-citem-body--top-title {
  text-align: left;
  font-weight: bold;
}

.rce-citem-body--bottom-title {
  text-align: left;
}

p {
  margin-bottom: 0;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

table {
  border: 1px solid #a0a0a0;
}

td,
th {
  border: 1px solid #a0a0a0;
  padding: 10px;
  text-align: center;
  width: 100px;
}

pre {
  margin: 0;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

body {
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.dialog-class {
  background-color: blue;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.remote-media {
  video {
    height: 160px;
  }
}

.react-date-picker__calendar,
.react-date-picker__calendar .react-calendar {
  border-width: thin !important;
}

.react-date-picker {
  display: inline-flex;
  position: relative;
  width: 100% !important;
  // height: 65%;
}

.react-date-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  border: thin solid #a9a9a9 !important;
  border-radius: 3px !important;
}

.react-date-picker__inputGroup__input {
  min-width: 0.54em;
  height: 100%;
  position: relative;
  padding: 0 1px;
  border: 0;
  background: none;
  font: inherit;
  box-sizing: content-box;
  -moz-appearance: textfield;
  color: #696969 !important;
}

.react-date-picker__button__icon {
  stroke: #696969;
}

.react-date-picker__button:enabled:hover .react-date-picker__button__icon,
.react-date-picker__button:enabled:focus .react-date-picker__button__icon {
  stroke: $primaryColor !important;
}

.react-calendar__tile--active {
  background: $primaryColor !important;
  color: white !important;
}

.react-calendar__month-view__days__day--weekend {
  color: black !important;
}

.react-calendar__tile--now {
  background: $primaryColorFadedMedium !important;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: $primaryColor !important;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #20a89233 !important;
}

.react-date-picker__inputGroup__input:invalid {
  background: #20a89233 !important;
}

.react-calendar__tile--hasActive {
  background: $primaryColor !important;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #20a89233 !important;
}

.fancy-field__label {
  font-weight: bold !important;
  font-size: 0.625rem !important;
}

.fancy-field__input {
  font-weight: 500 !important;
}
