@import '../../../../variables.scss';

.textInput {
  border: 1px solid #d0d0d0;
  padding: 5px 10px;
  border-radius: 4px;
  width: 50%;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
}

.rowLabel {
  color: $primaryColor;
  margin-right: 30px;
  width: 20%;
  font-size: large;
}

.infoWrapper {
  width: 100%;
  box-shadow: 5px 5px 5px 5px grey;
  padding: 10px 40px;
  border-radius: 5px;
}

.closeModalBtn {
  position: absolute;
  top: 5px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
  padding: 4px 10px;
}

.closeModalBtn:hover {
  background-color: #acacac;
}

.subRowLabel {
  color: $primaryColor;
  margin-right: 30px;
}
