@import '../../../../variables.scss';

.row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.chartWrapper {
  width: 90%;
  box-shadow: 5px 5px 5px 5px grey;
}

.rowIcon {
  flex-direction: column;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  cursor: pointer;
}

.tabContentWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  width: 100%;
  padding: 0 20px;
}

.contentBackground {
  background-color: $contentBackground;
  border-radius: $contentBorderRadius;
  padding: 10px 20px;
}

.contentRow {
  display: flex;
  flex-direction: row;
  margin-top: 4px;
  align-items: center;
  border-bottom: 1px solid #e8e8e8;
  padding: 3px 0px;
  min-height: 40px;
}

.carePlanRow {
  display: flex;
  flex-direction: row;
  // margin-top: 10px;
  align-items: center;
  // border-top: 1px solid #e8e8e8;
  padding: 3px 0px;
  justify-content: space-between;
}

.contentRowKey {
  color: gray;
  font-weight: bold;
  width: 160px;
}

.contentRowValue {
  margin-left: 10px;
}

.rowKey {
  color: $primaryColor;
  width: 20%;
}

.rowValue {
  display: inline;
  overflow-wrap: break-word;
}

.tableHeader {
  font-weight: bold;
  width: 25%;
  text-align: center;
  color: gray;
}

.externalTableHeader {
  font-weight: bold;
  width: 20%;
  text-align: center;
  color: gray;
  margin: 4px;
}

.externalTableEntry {
  width: 20%;
  text-align: center;
  color: black;
  margin: 4px;
}

.redDot {
  height: 14px;
  width: 14px;
  border-radius: 50%;
  background-color: red;
}

.closeModalBtn {
  position: absolute;
  top: 5px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
  padding: 4px 10px;
}

.closeModalBtn:hover {
  background-color: #acacac;
}

.docImageWrapper {
  height: 200px;
  width: 500px;
  margin: 20px;
  border-radius: $contentBorderRadius;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: $contentBackground;
  padding: 4px 6px;
}

.externalConditionsElement {
  height: 120px;
  width: 30%;
  margin: 10px;
  border-radius: $contentBorderRadius;
  background-color: $contentBackground;
  padding: 10px;
  position: relative;
}

.externalConditionsWrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
}

.tabItem {
  border-right-width: 1px;
  border-right-color: gray;
  border-right-style: solid;
  flex-grow: 1;
  text-align: center;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.textInput {
  border: 1px solid #d0d0d0;
  padding: 5px 10px;
  border-radius: 4px;
  width: 50%;
}

.addManualRow {
  display: flex;
  flex-direction: row;
  width: 100%;
  // align-items: center;
  padding: 4px;
  position: relative;
  margin-top: 20px;
  justify-content: space-around;
  align-items: center;
}

.addManualCol {
  display: flex;
  flex-direction: column;
  width: 30%;
  // align-items: center;
  padding: 4px;
  position: relative;
  margin-left: 20px;
}

.addManualQuestion {
  color: $primaryColor;
  align-self: center;
  font-size: 20px;
  width: 25%;
  margin-left: 30px;
  overflow: visible;
}

.addManualTextInput {
  border: 1px solid #d0d0d0;
  padding: 5px 20px;
  border-radius: 4px;
  width: 70%;
  // height: 40px;
}

.selectedPtag {
  border: 1px solid #d0d0d0;
  padding: 5px 20px;
  border-radius: 4px;
  width: 70%;
  height: 90%;
}

.addManualSelectInput {
  border-radius: 4px;
  width: 70%;
}

.addRadioSelectInput {
  padding: 4px;
  // border-radius: 4px;
  width: 70%;
  display: flex;
  flex-direction: row;
  position: relative;
  justify-content: space-evenly;
}

.addSelectInput {
  border-radius: 4px;
  width: 70%;
}

.addSliderInput {
  // border: 1px solid #d0d0d0;
  padding: 5px 40px;
  border-radius: 4px;
  width: 70%;
  // height: 40px;
}

.sliderContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 20px;
}

.sliderLabelLeft {
  font-size: 16px;
  font-weight: normal;
  color: $primaryColor;
  width: 120px;
  text-align: center;
  margin-left: -45px;
}

.sliderLabelRight {
  font-size: 16px;
  font-weight: normal;
  color: $primaryColor;
  width: 120px;
  text-align: center;
  margin-right: -50px;
}
.addManualSelectInput {
  padding: 5px 20px;
  border-radius: 4px;
  width: 80%;
}

.addRadioSelectInput {
  padding: 4px;
  // border-radius: 4px;
  width: 80%;
  display: flex;
  flex-direction: row;
  position: relative;
  justify-content: space-evenly;
}
.tooltip {
  width: 400px;
  white-space: normal;
}
.divider {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 10px;
}
