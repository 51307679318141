@import '../../../variables.scss';

.react-datepicker-wrapper {
  margin: 50px;
}

.datePicker {
  margin: 10px;
}

.deleteIcon {
  color: #20a892;
  font-size: 20px;
  cursor: pointer;
  margin: 10px;
}

.closeModalBtn {
  position: absolute;
  top: 0px;
  right: 0px;
  font-size: 24px;
  cursor: pointer;
  padding: 4px 6px;
}

.closeModalBtn:hover {
  background-color: #acacac;
}

.modalRow {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  padding: 4px;
}

.modalKey {
  width: 50%;
  color: gray;
}

.modalValue {
  width: 50%;
  overflow-wrap: break-word;
  margin-left: 4px;
  color: black;
}

.refreshIcon {
  font-size: 20px;
  color: $primaryColor;
  margin: 20px;
  cursor: pointer;
}

.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}
.custom-file-input::before {
  content: 'Select some files';
  display: inline-block;
  background: linear-gradient(top, #f9f9f9, #e3e3e3);
  border: 1px solid #999;
  border-radius: 3px;
  padding: 5px 8px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  font-weight: 700;
  font-size: 10pt;
}
.custom-file-input:hover::before {
  border-color: black;
}
.custom-file-input:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

.closeModalBtn {
  position: absolute;
  // top: 5px;
  // right: 10px;
  font-size: 24px;
  cursor: pointer;
  padding: 4px 10px;
}

.closeModalBtn:hover {
  background-color: #acacac;
}

.checkContainer {
  display: block;
  position: relative;
  padding-left: 35px;
  // margin-bottom: 12px;
  margin: 0%;
  cursor: pointer;
  font-size: 18px;
  // color: $primaryThemeDark;
  font-family: 'Avenir';
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

.checkContainer:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkContainer input:checked ~ .checkmark {
  background-color: #20a892;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkContainer input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkContainer .checkmark:after {
  left: 10px;
  top: 6px;
  width: 5px;
  height: 12px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.dateInputButton {
  background-color: white;
  border-color: grey;
  width: 120px,;
  margin-top: 10px;
  margin-bottom: 10px;
  color: black;
}

.dateInputButton:hover {
  background-color: rgb(242, 242, 242);
  border-color: gray;
  color: black
}

.dateInputButton:disabled {
  background-color: rgb(191, 191, 191);
}

.dateInputButton:active {
  background-color: white;
  border-color: gray;
  color: black;
}

.dateInputButton:focus {
  background-color: white;
  color: black;
}