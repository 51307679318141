@import "../../../variables.scss";

.sectionDivider {
  border-bottom: 1px solid $primaryColor;
  padding: 40px 30px;
  min-height: 300px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.rowIcon{
  flex-direction: column;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  background-color: $primaryThemeDark;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rowIconText {
  color: white;
  font-weight: bold;
  font-size: 12px;
  text-align: center;
  width: 80%;
  word-wrap: break-word;
  hyphens: manual;
}

.entrySection {
  background-color: #e8e8e8;
  width: 90%;
  margin-left: 20px;
  border-radius: 10px;
  display: flex;
  height: 300px;
  overflow-y: scroll;
  padding: 40px 40px 40px;
  flex-direction: column;
}

.row {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 10px;
}

.entry {
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.redDot {
  height: 14px;
  width: 14px;
  border-radius: 50%;
  background-color: red;
  margin-right: 10px;
}
