@import '../../../variables.scss';

.tabContentWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  width: 100%;
  padding: 0 20px;
}

.contentBackground {
  background-color: $contentBackground;
  border-radius: $contentBorderRadius;
  width: 73vw;
  padding: 10px 10px;
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  height: 70vh;
}

.contentRow {
  margin-top: 4px;
  align-items: center;
  border-bottom: 1px solid #e8e8e8;
  padding: 5px 0px;
  width: 2600px;
  // min-height: 50px;
}

.tableHeader {
  font-weight: bold;
  width: 25%;
  text-align: center;
  color: gray;
  display: inline-block;
  word-wrap: break-word;
  white-space: normal;
  padding: 0px 5px;
  // border-bottom: 1px solid #e8e8e8;
}
.smtableHeader {
  font-weight: bold;
  width: 150px;
  text-align: center;
  color: gray;
  display: inline-block;
  word-wrap: break-word;
  white-space: normal;
  padding: 0px 5px;
  // border-bottom: 1px solid #e8e8e8;
}
.mtableHeader {
  font-weight: bold;
  width: 200px;
  text-align: center;
  color: gray;
  display: inline-block;
  word-wrap: break-word;
  white-space: normal;
  padding: 0px 5px;
  // border-bottom: 1px solid #e8e8e8;
}
.xtableHeader {
  font-weight: bold;
  width: 300px;
  text-align: center;
  color: gray;
  display: inline-block;
  word-wrap: break-word;
  white-space: normal;
  padding: 0px 5px;
  // border-bottom: 1px solid #e8e8e8;
}

.smtableData {
  width: 150px;
  text-align: center;
  display: inline-block;
  word-wrap: break-word;
  white-space: normal;
  padding: 0px 5px;
  // border-bottom: 1px solid #e8e8e8;
}
.mtableData {
  width: 200px;
  text-align: center;
  display: inline-block;
  word-wrap: break-word;
  white-space: normal;
  padding: 0px 5px;
  // border-bottom: 1px solid #e8e8e8;
}
.xtableData {
  width: 300px;
  text-align: center;
  display: inline-block;
  word-wrap: break-word;
  white-space: normal;
  padding: 0px 5px;
  // border-bottom: 1px solid #e8e8e8;
}

.redDot {
  height: 14px;
  width: 14px;
  border-radius: 50%;
  background-color: red;
}

.docImageWrapper {
  height: 200px;
  width: 500px;
  margin: 20px;
  border-radius: $contentBorderRadius;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: $contentBackground;
  padding: 4px 6px;
}

.tabItem {
  border-right-width: 1px;
  border-right-color: gray;
  border-right-style: solid;
  flex-grow: 1;
  text-align: center;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.textInput {
  border: 1px solid #d0d0d0;
  padding: 5px 10px;
  border-radius: 4px;
  width: 50%;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 30px;
}

.rowLabel {
  color: $primaryColor;
  margin-right: 30px;
  width: 5%;
}

.addSelectInput {
  width: 90%;
}

.link {
  color: #20a892;
}
.link:hover {
  color: #20a892;
}

table {
  // font-family: 'Trebuchet MS', Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  // width: 100%;
  width: 73vw;
  // padding: 10px 10px;
  // white-space: nowrap;
  // display: flex;
  // flex-direction: column;
  overflow: scroll;
  height: 70vh;
}

table td,
table th {
  border: 1px solid #ddd;
  padding: 8px;
}

table tr:nth-child(even) {
  background-color: #f2f2f2;
}

table tr:hover {
  background-color: #ddd;
}

table th,
tfoot td {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: $contentBackground;
  // background-color: #4caf50;
  color: gray;
}

.pagination {
  padding: 0.5rem;
}
.divider {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 10px;
}
